import { ready } from "../utils/listeners";
import api from "../api";

const PERMITTED_TRACK_EVENTS = [
  "Viewed Product",
  "Customised Product",
  "Search",
  "Added Product",
  "Checkout Started",
  "Completed Order",
  "Submitted B2B Form",
  "PageView",
  "Cart Viewed",
  "Checkout Step Completed",
  "Added Sample",
  "Completed A Wedding Purchase",
  "Completed A Sample Purchase",
  "Completed A Wedding Sample Purchase",
  "Feature Viewed"
];

window.serverSideAnalytics = {
  // Look at the _segment partial in the layout
  // to see the middleware that calls this method
  setupServerEvent({ obj, options }) {
    const vm = this;
    // Timeout to ensure that the client side event is sent first
    setTimeout(() => {
      switch (obj.type) {
        case "identify":
          vm.identifyServer(obj);
          break;
        case "page":
          vm.pageServer(obj);
          break;
        case "track":
          vm.trackServer(obj, options);
          break;
        default:
          break;
      }
    }, 1000);
  },
  identifyServer({ messageId, userId, traits, context }) {
    api.post("/segment_s2s/identify", {
      user_id: userId,
      traits,
      pixel_event_id: messageId,
      consent: context.consent
    });
  },
  pageServer({ messageId, name, properties, context }) {
    api.post("/segment_s2s/page", {
      name,
      properties,
      pixel_event_id: messageId,
      consent: context.consent
    });
  },
  trackServer({ messageId, event, properties, context }) {
    if (PERMITTED_TRACK_EVENTS.includes(event)) {
      api.post("/segment_s2s/track", {
        event,
        properties,
        pixel_event_id: messageId,
        consent: context.consent
      });
    }
  }
};

ready(() => {
  window.analyticsIdentifyQueue.forEach(identifyArgs => {
    analytics.identify(...identifyArgs);
  });
  window.analyticsPageViewQueue.forEach(pageArgs => {
    analytics.page(...pageArgs);
  });
  window.analyticsTrackQueue.forEach(trackArgs => {
    analytics.track(...trackArgs);
  });
});
